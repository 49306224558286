import {
  SlOption
} from "./chunk.SQHT6ZTG.js";

// src/components/option/option.ts
var option_default = SlOption;
SlOption.define("sl-option");

export {
  option_default
};
