import {
  SlTabPanel
} from "./chunk.DHQRDM5R.js";

// src/components/tab-panel/tab-panel.ts
var tab_panel_default = SlTabPanel;
SlTabPanel.define("sl-tab-panel");

export {
  tab_panel_default
};
