import {
  SlCard
} from "./chunk.5UYCBQAR.js";

// src/components/card/card.ts
var card_default = SlCard;
SlCard.define("sl-card");

export {
  card_default
};
