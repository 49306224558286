import {
  SlTab
} from "./chunk.PE2GL2GC.js";

// src/components/tab/tab.ts
var tab_default = SlTab;
SlTab.define("sl-tab");

export {
  tab_default
};
