import {
  SlTabGroup
} from "./chunk.TYOTZMEY.js";

// src/components/tab-group/tab-group.ts
var tab_group_default = SlTabGroup;
SlTabGroup.define("sl-tab-group");

export {
  tab_group_default
};
